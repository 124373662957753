body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

.video-wrapper {
  position: relative;
}

.video-wrapper > video {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.watermark {
  color: rgb(255, 136, 0) !important;
  position: absolute;
  transform: scale(-1, 1);
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 0px 0px;
  padding: 5px 10px;
}
